import { render, staticRenderFns } from "./PlaceBooking.vue?vue&type=template&id=fe4ab87a"
import script from "./PlaceBooking.vue?vue&type=script&lang=js"
export * from "./PlaceBooking.vue?vue&type=script&lang=js"
import style0 from "./PlaceBooking.vue?vue&type=style&index=0&id=fe4ab87a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports